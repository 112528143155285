@import "variables";

/* Container
------------------------------------------------------*/
#container {
  border-top: 11px solid #416300 !important;
}

/* Footer
------------------------------------------------------*/
#footer-content {
  color: #82a049;
}

#footer-address p {
  color: #82a049;
}

#footer-address img {
  margin-bottom: 10px;
}

#footer-last {
  width: 960px;
  margin-top: 36px;
  border-top: 2px solid #75923d;
  float: left;
}

/* Footer News list */
#footer-news h3 {
  margin-bottom: 7px;
  color: #e3e3e3;
}

ul.list-bottom li {
  background-image: url($staticdir + 'hansili/green/bottom-dot.png');
  background-position: bottom;
  background-repeat: repeat-x;
  padding-bottom: 4px;
  line-height: 26px;
}

ul.list-bottom li a {
  color: #82a049;
  text-decoration: none;
}

ul.list-bottom li a:hover {
  text-decoration: underline;
}

#footer-copyright a {
  color: #82a049;
}

/* Mainmenu Styling */
.navigation-footer li a {
  font-size: 11px;
  text-decoration: none;
  color: #82a049;
  display: block;
  margin: 0px;
  padding-right: 24px;
  float: left;
  z-index: 100;
}

.navigation-footer li.current a, .navigation-footer li a:hover, .navigation-footer li.current li a:hover {
  text-decoration: none;
  color: #82a049;
}

.navigation-footer li.current li a {
  text-decoration: none;
  color: #82a049;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #82a049;
  border-color: #82a049;
}

.modal-dialog {
  width: 1024px;
}

.modal-picture {
  text-align: center;
  vertical-align: middle;
}

ol, ul.square, ul.circle, ul.disc {
  margin-left: 0px;
}

.modal-loading {
  display: none;
  height: 500px;
  position: relative;

}

.modal-loading img {
  text-align: center;
  vertical-align: middle;
  position: absolute;
  top: 40%;
  left: 40%;
}

.bs-callout-danger {
  background-color: #fdf7f7;
  border-color: #eed3d7;
}

.bs-callout {
  margin: 20px 0;
  padding: 20px;
  border-left: 3px solid #eee;
}

.bs-callout-danger h4 {
  color: #b94a48;
}

.bs-callout h4 {
  margin-top: 0;
  margin-bottom: 5px;
}

.bs-callout p:last-child {
  margin-bottom: 0;
}

#page-title {
  width: 100%;
  height: 86px;
  //background-color: #ebebeb;
  float: left;
  margin: 0px;
}