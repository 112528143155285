@import "variables";
@import "bootstrap-theme";
$icon-font-path: "/assets/lib/bootstrap-sass/assets/fonts/bootstrap/";
@import "lib/bootstrap-sass/assets/stylesheets/bootstrap";
@import "green";
@import "new";

html, body {
  height: 100%;
}

body {
  font-family: Arial, Tahoma, Verdana, serif;
  font-size: 12px;
  margin: 0 auto;
  padding: 0;
  background-color: #fff;
  color: #a5a6a6;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
}

*:focus {
  outline: none;
  /* removes ugly dotted border but may make template more unsuable, up to you
                  if you want to keep it! */
}

.clr {
  clear: both
}

form {
  margin: 0;
  padding: 0;
}

hr {
  border: 0;
  color: #d3d3d3;
  background-color: #d3d3d3;
  height: 1px;
}

hr.content-line {
  border: 0;
  color: #d0d0d0;
  background-color: #d3d3d3;
  margin: 30px 0px 30px 0px;
  height: 1px;
}

.line-divider {
  background-repeat: repeat-x;
  background-position: bottom center;
  background-image: url($staticdir + 'images/bottom-dot2.png');
  height: 1%;
  padding: 1px 0;
}

/* Default Element Styles
------------------------------------------------------*/
/* heading */
h1, h2, h3, h4, h5, h6 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: #65696e;
}

h1 {
  font-size: 28px;
  line-height: 30px;
  margin-bottom: 10px;
}

h2 {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 10px;
}

h3 {
  font-size: 22px;
  line-height: 20px;
  margin-bottom: 10px;
}

h4 {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 8px;
  font-weight: bold;
}

h5 {
  font-size: 16px;
  margin: 3px 0px;
  padding: 0px;
}

h6 {
  font-size: 14px;
  margin: 3px 0px;
  padding: 0px;
}

/* links */
a, a:link {
  color: #a7a6a6;
  text-decoration: none;
}

a:visited {
  color: #a7a6a6;
  text-decoration: none;
}

a:hover {
  color: #6c7074;
  text-decoration: none;
}

a img {
  border: none
}

.imgcenter {
  float: left;
  margin: 0px 0px 10px 0px;
}

img.aligncenter {
  margin: 2px auto 15px auto;
  display: block;
}

.imgleft,
.alignleft,
.avatar {
  float: left;
  margin: 4px 12px 10px 0px;
}

.imgright,
.alignright {
  float: right;
  margin: 4px 0px 10px 12px;
}

.border {
  padding: 4px;
  border: 1px solid #ebebeb;
}

.roundborder {
  background-color: #ebebeb;
  padding: 12px;
}

/* lists, blockquotes and paragraphs */
p, ul, ol, blockquote {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 14px;
  color: #a5a6a6;
}

ul, ol {
  margin: 0 0 20px;
}

ul {
  list-style: none outside;
}

ol {
  list-style: decimal;
}

ol, ul.square, ul.circle, ul.disc {
  margin-left: 15px;
}

ul.square {
  list-style: square outside;
}

ul.circle {
  list-style: circle outside;
}

ul li {
  line-height: 24px;
}

ul.disc {
  list-style: disc outside;
}

ul.star li {
  background-repeat: no-repeat;
  background-position: 0 4px;
  background-image: url($staticdir + 'images/list-star.png');
  background-color: transparent;
  padding: 0 0 0 23px;
}

ul.arrow2 li {
  background-repeat: no-repeat;
  background-position: 0 6px;
  background-image: url($staticdir + 'images/list-greenarw.png');
  background-color: transparent;
  padding: 0 0 0 23px;
}

ul.checklist li {
  background-repeat: no-repeat;
  background-position: 0 4px;
  background-image: url($staticdir + 'images/check.png');
  background-color: transparent;
  padding: 0 0 0 23px;
}

ul.cross li {
  background-repeat: no-repeat;
  background-position: 0 6px;
  background-image: url($staticdir + 'images/list-cross.png');
  background-color: transparent;
  padding: 0 0 0 23px;
}

ul.gear li {
  background: transparent url($staticdir + 'images/list-gear.png') no-repeat 0 6px;
  padding: 0 0 0 23px;
}

ul.pen li {
  background: transparent url($staticdir + 'images/list-pen.png') no-repeat 0 0px;
  padding: 0 0 0 23px;
}

ul.arrow li {
  background: transparent url($staticdir + 'images/list-arrow.png') no-repeat 0 9px;
  padding: 0 0 0 18px;
}

ul.arrow,
ul.checklist,
ul.circle,
#slideshow ul li ul.arrow,
#slideshow ul li ul.checklist,
#slideshow ul li ul.circle,
ul.display li ul.arrow,
ul.display li ul.checklist,
ul.display li ul.circle {
  margin: 0 0 20px 0;
  padding: 0;
  list-style: none;
}

ul.display li ul.checklist {
  margin: 0 0 0 0;
  padding: 0;
}

#slideshow ul li ul.arrow,
#slideshow ul li ul.checklist,
#slideshow ul li ul.circle {
  margin: 0;
  padding: 0;
  list-style: none;
  clear: both;
}

#slideshow ul li ul.checklist,
#slideshow ul li ul.circle {
  margin: 0;
  padding: 0;
  list-style: none;
  float: none;
}

#slideshow ul li ul.arrow li {
  line-height: 24px;
  list-style-type: none;
}

#slideshow ul li ul.checklist li,
ul.display li ul.checklist li {
  background-image: url($staticdir + 'images/check.png');
  background-position: 0px 6px;
  background-repeat: no-repeat;
  padding: 0px 0px 0px 22px;
  line-height: 24px;
  list-style-type: none;
}

ul.display li ul.checklist li {
  float: left;
  width: 463px;
  margin-left: 20px;
  margin-bottom: 0;
}

ul.circle li,
#slideshow ul li ul.circle li {
  background-image: url($staticdir + 'images/bullet.png') !important;
  background-position: 0px center;
  background-repeat: no-repeat;
  padding: 0px 0px 0px 20px !important;
  line-height: 20px !important;
  list-style-type: none;
}

#slideshow ul li ul.arrow li,
#slideshow ul li ul.checklist li,
#slideshow ul li ul.circle li {
  width: auto;
  height: auto;
  float: none;
  line-height: 18px !important;
}

/* Pullquotes */
.pullquote_right, .pullquote_left {
  color: #9c9c9c;
  float: right;
  font-size: 1.2em;
  letter-spacing: 0px;
  margin-bottom: 0;
  margin-top: 7px;
  margin-left: 20px;
  padding-left: 15px;
  width: 40%;
  font-weight: bold;
  font-style: italic;
  line-height: 1.4em;
  border-left: 2px solid #cccccc;
}

.pullquote_left {
  border-right: 2px solid #cccccc;
  border-left: none;
  float: left;
  margin-left: 0px;
  margin-right: 10px;
  padding-left: 0;
  padding-right: 15px;
}

/* Dropcap */
.dropcap {
  float: left;
  font-size: 30px;
  height: 41px;
  line-height: 30px;
  margin-bottom: -10px;
  margin-right: 0px;
  padding-top: 3px;
  text-align: center;
  width: 41px;
}

/* Custom Message Styling */
.info {
  margin: 15px 0;
  color: #478BBF;
  padding: 8px 10px 8px 37px;
  background: #DCEFF5 url($staticdir + 'images/info.png') 12px 9px no-repeat;
  border: 1px solid #B8E7F5;
}

.success {
  margin: 15px 0;
  color: #3F9153;
  padding: 8px 10px 8px 37px;
  background: #D7F7DF url($staticdir + 'images/success.png') 12px 9px no-repeat;
  border: 1px solid #A3F7B8
}

.error {
  margin: 15px 0;
  color: #C24848;
  padding: 8px 10px 8px 37px;
  background: #FFD6D6 url($staticdir + 'images/error.png') 12px 9px no-repeat;
  border: 1px solid #FFC2C2;
}

.warning {
  margin: 15px 0;
  color: #CF9E00;
  padding: 8px 10px 8px 37px;
  background: #FAF2D7 url($staticdir + 'images/warning.png') 12px 9px no-repeat;
  border: 1px solid #FAE8AF;
}

/*  Highlight Styles */

.highlight-yellow {
  padding: 1px 4px;
  background-color: yellow;
  color: #111;
}

.highlight-dark {
  padding: 1px 4px;
  background-color: #6a6a6a;
  color: #fff;
}

.highlight-red {
  padding: 1px 4px;
  background-color: #DE2D2D;
  color: #fff;
}

.highlight-green {
  padding: 1px 4px;
  background-color: #A3CC29;
  color: #111;
}

/***** Buttons  *****/
a.button {
  display: inline-block;
  font-size: 11px;
  text-transform: uppercase;
  color: #fff;
  background: url($staticdir + 'images/btn-arrow.png') no-repeat right;
  cursor: pointer;
  text-decoration: none;
  margin: 0 0 10px;
  padding: 8px 18px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

a:hover.button {
  text-decoration: underline;
}

.button.small {
  font-size: 11px;
}

.button.medium {
  font-size: 13px;
}

.button.large {
  font-size: 15px;
}

.button.black {
  background-color: #333;
}

.button.gray {
  background-color: #666;
}

.button.white {
  background: #fff url($staticdir + 'images/btn-arrow2.png') no-repeat right;
  color: #666;
  border: 1px solid #d3d3d3;
}

.button.red {
  background-color: #e62727;
}

.button.orange {
  background-color: #ff5c00;
}

.button.magenta {
  background-color: #A9014B;
}

.button.yellow {
  background-color: #ffb515;
}

.button.blue {
  background-color: #00ADEE;
}

.button.pink {
  background-color: #e22092;
}

.button.green {
  background-color: #a2c437;
}

.button.rosy {
  background-color: #F16C7C;
}

.button.brown {
  background-color: #804000;
}

.button.purple {
  background-color: purple;
}

.button.cyan {
  background-color: #46C7C7;
}

.button.gold {
  background-color: #D4A017;
}

.divider {
  border-bottom: 1px solid #ebebeb;
  clear: both;
  margin: 20px 0;
}

li ol {
  list-style: decimal;
}

blockquote {
  font-style: italic;
  margin: 0px;
  color: #6e7a7f;
  padding: 0px 4px 2px 38px;
  background-image: url($staticdir + 'images/quote.png');
  background-position: 0px 5px;
  background-repeat: no-repeat;
  float: left;
}

/* code */
code {
  font-family: Tahoma, Arial, Verdana;
  letter-spacing: 1px;
  margin: 25px 0 25px 0px;
  display: block;
  font-size: 0.9em;
  border-left: 4px solid #dddddd;
  padding: 15px 10px;
}

pre {
  clear: both;
  font: 11px Consolas, "Andale Mono", Courier, "Courier New", monospace;
  background: url($staticdir + 'images/pre_bg.gif') repeat;
  border: 1px solid #d3d3d3;
  padding: 18px 20px 15px 20px;
  line-height: 19px;
  overflow: auto;
  overflow-Y: hidden;
  color: #989898;
  margin: 0 0 20px 0;
}

/* Container
------------------------------------------------------*/
#container {
  border-top: 11px solid #65696e;
}

#top-container {
  width: 960px;
  height: 522px;
  margin: 0px auto 10px;
}

#top-container-inner {
  width: 960px;
  height: 104px;
  margin: 0px auto;
}

#mid-container {
  width: 100%;
  height: 189px;
  margin-top: -10px;
  background-color: #ebebeb;
}

/* Header
------------------------------------------------------*/
#header {
  width: 960px;
  height: 104px;
  float: left;
  margin-bottom: 30px;
}

#logo {
  width: 200px;
  margin: 0px;
  padding: 29px 0px 0px 17px;
  float: left;
}

/* Mainmenu Styling */
#mainmenu {
  width: auto;
  height: 41px;
  margin-top: 37px;
  float: right;
  margin-right: 10px;
}

.navigation {
  width: auto;
  float: right;
  list-style-type: none;
}

.navigation ul {
  margin: 0px;
  padding: 6px 0 0;
  list-style-type: none;
  position: relative;
  clear: both;
  float: right;
}

.navigation li {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  float: left;
  z-index: 100;
  position: relative;
}

.navigation li a {
  font-size: 16px;
  text-decoration: none;
  color: #65696e;
  display: block;
  margin: 0px;
  padding: 0px 44px 10px 0px;
  float: left;
  z-index: 100;
}

#mainmenu .navigation li.last a {
  padding-right: 0;
}

.navigation li.current a, .navigation li a:hover, .navigation li.current li a:hover {
  text-decoration: none;
  color: #989494;
}

.navigation li.current a,
.navigation li.current_page_item a,
.current-menu-item.last a,
.navigation li.current-menu-item a {
  text-decoration: none;
  color: #989494;
}

.navigation li li {
  margin: 0 0 0px 0px;
  padding: 0px;
  position: relative;
  z-index: 100;
}

.navigation ul {
  display: none;
  position: absolute;
  top: 25px;
  left: -17px;
  width: 160px;
  z-index: 100;
  background-color: #fff;
  border-top: none;
  border-bottom: 2px solid #ebebeb;
  border-left: 2px solid #ebebeb;
  border-right: 2px solid #ebebeb;
  -moz-border-radius-bottomleft: 9px;
  -moz-border-radius-bottomright: 9px;
  -webkit-border-bottom-left-radius: 9px;
  -webkit-border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
}

.navigation li ul a {
  width: 150px;
  float: left;
  padding: 0px 25px 8px 15px;
  font-size: 13px;
  color: #65696e;
  line-height: 20px;
  z-index: 100;
  text-decoration: none;
}

.navigation ul ul {
  display: none;
  position: absolute;
  top: 0;
  left: 160px;
  width: 160px;
  z-index: 100;
  background-color: #fff;
  border-top: none;
  border-bottom: 2px solid #ebebeb;
  border-left: 2px solid #ebebeb;
  border-right: 2px solid #ebebeb;
  -moz-border-radius-bottomleft: 9px;
  -moz-border-radius-bottomright: 9px;
  -webkit-border-bottom-left-radius: 9px;
  -webkit-border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
}

/* Welcome Slogan Styling */
#slogan {
  width: 700px;
  margin-top: 15px;
  float: left;
}

#get-in-touch {
  margin-right: 5px;
  width: 210px;
  height: 53px;
  margin-top: 25px;
  float: right;
  background: #EBEBEB;
}

img.get-in-touch {
  position: relative;
  bottom: 5px;
  left: -20px;
}

.intouch {
  margin: 15px 10px 15px 0;
  float: right;
  text-align: center;
  display: block;
}

.dot-separator {
  width: 35px;
  height: 54px;
  margin-top: 20px;
  background-image: url($staticdir + 'hansili/images/dot-separator.png');
  background-position: center;
  background-repeat: repeat-y;
  float: left;
}

/* Page-title Styling */

#page-title-inner {
  width: 960px;
  margin: 0px auto;
}

.title {
  margin: 5px 0px 0px 18px;
  float: left;
}

.description {
  margin: 25px 0px 0px 0px;
  width: 516px;
  float: left;
}

.dot-separator-title {
  width: 64px;
  height: 39px;
  margin-top: 22px;
  background-image: url($staticdir + 'hansili/images/dot-separator.png');
  background-position: center;
  background-repeat: repeat-y;
  float: left;
}

/*  Slideshow
------------------------------------------------------*/
#slideshow {
  width: 954px;
  height: 298px;
  background-color: #ebebeb;
  float: left;
}

#slideshow ul, #slideshow li {
  width: 936px;
  height: 280px;
  margin: 6px 0px 0px 6px;
  padding: 0px;
  list-style-type: none;
  overflow: hidden;
  float: left;
}

#slideshow li a span.slide-text-bottom {
  color: #fff;
}

#pager {
  position: absolute;
  z-index: 10;
  top: 375px;
  margin-left: 850px;
}

#pager a {
  background: url($staticdir + 'images/slide.png') no-repeat scroll 50% 50%;
  display: block;
  float: left;
  font-size: 0px;
  width: 13px;
  height: 13px;
  margin: 2px;
  outline-style: none;
  outline-width: medium;
  padding: -1px;
}

#pager .activeSlide {
  background: url($staticdir + 'images/slide.png') no-repeat scroll 0% 50%;
}

.clear {
  clear: both;
}

ul {
  list-style-type: none;
}

/* Slideshow alt2 */
.slide-img,
.slide-img-right {
  width: 610px;
  height: 280px;
  margin-right: 26px;
  float: left;
}

.slide-img-right {
  margin-right: 0;
  margin-left: 26px;
}

.slide-text,
.slide-text-left {
  width: 282px;
  margin-top: 12px;
  float: left;
}

.slide-text-left {
  margin-left: 16px;
}

.slide-text-bottom {
  height: 48px;
  padding: 10px 100px 10px 20px;
  width: 810px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #000000;
  opacity: 0.7;
}

.heading1-slide {
  width: 282px;
  font-size: 22px;
  color: #65696e;
  line-height: 20px;
  margin-bottom: 10px;
  float: left;
}

.heading2-slide {
  width: 282px;
  font-size: 14px;
  color: #65696e;
  margin-bottom: 15px;
  float: left;
}

.slide-text p {
  width: 282px;
  margin: 13px 0px 0px 0px;
  float: left;
}

.slide-more {
  height: 29px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin: 0 0px 0px 0px;
  padding: 6px 20px 0px 20px;
  background-color: #fff;
  position: absolute;
  bottom: 10px;
}

.slide-more a, .slide-more a:visited {
  color: #65696e;
  text-decoration: none;
}

#slideshow ul li ul {
  margin-bottom: 0 !important;
}

/* Content
------------------------------------------------------*/
#content {
  width: 960px;
  margin: 0px auto;
}

#content-left {
  width: 622px;
  margin-right: 50px;
  float: left;
}

#content-right {
  width: 288px;
  margin: 49px 0px;
  float: left;
}

#content-fullwidth {
  width: 960px;
  float: left;
}

.maincontent {
  margin: 20px 0px 20px 0px;
  float: left;
  width: 100%;
}

.sidebar {
  width: 267px;
  border-left: 2px solid #ebebeb;
  padding-left: 21px;
  float: left;
}

.sidebar-bottom {
  width: 288px;
  height: 11px;
  margin-bottom: 34px;
  background-image: url($staticdir + 'images/sidebar-bottom.gif');
  background-position: bottom;
  background-repeat: no-repeat;
  float: left;
}

/* Content list */
ul.sidebar-list,
.sidebar ul,
.widget ul {
  margin: 0px;
  padding: 0px 0px 13px 0px;
  list-style-type: none;
}

ul.sidebar-list li,
.sidebar ul li,
.widget ul li {
  background-image: url($staticdir + 'images/bottom-dot2.png');
  background-position: bottom;
  background-repeat: repeat-x;
  padding-bottom: 4px;
  line-height: 26px;
}

ul.sidebar-list li a,
.sidebar ul li a,
.widget ul li a {
  color: #a6a6a6;
  text-decoration: none;
}

ul.sidebar-list li a:hover,
.sidebar ul li a:hover,
.widget ul li a:hover {
  text-decoration: underline;
}

.sidebar ul.ads-list li {
  border: none;
  background: none;
}

/* Front Box Styling */
#content .front-box-content {
  margin-bottom: 45px;
}

#content .front-box-content2 {
  margin-bottom: 45px;
}

#front-box {
  width: 960px;
  margin: 0px auto;
}

.front-box-content {
  width: 286px;
  margin-top: 25px;
  float: left;
}

.front-box-content2 {
  width: 622px;
  margin-top: 25px;
  float: left;
}

.front-box-content3 {
  width: 286px;
  margin-top: 25px;
  float: left;
}

.front-box-content-client {
  width: 286px;
  margin-top: 25px;
  float: left;
}

.front-box-content h4 {
  color: #3e4043;
  font-weight: normal;
}

.front-box-content h4 a {
  color: #3e4043;
}

.img-front {
  width: 79px;
  height: 150px;
  float: left;
}

.dot-separator-content {
  width: 50px;
  height: 138px;
  margin-top: 22px;
  background-image: url($staticdir + 'images/dot-separator.png');
  background-position: center;
  background-repeat: repeat-y;
  float: left;
}

.separator-content {
  width: 50px;
  display: block;
  float: left;
}

/* Client Logo list */
ul.client-list,
.sidebar ul.client-list,
.footer-widget ul.client-list {
  width: 288px;
  margin: 0px 0px 0px 2px;
  padding: 0px;
  list-style-type: none;
}

.footer-widget ul.client-list {
  width: 100%;
}

ul.client-list li,
.sidebar ul.client-list li,
.footer-widget ul.client-list li {
  width: 64px;
  margin: 0px 10px 6px 0px;
  float: left;
  padding: 0;
  background: none;
}

.sidebar ul.client-list li {
  margin: 0px 20px 12px 0px;
}

ul.client-list li.client-last,
.sidebar ul.client-list li.client-last,
.footer-widget ul.client-list li.client-last {
  width: 64px;
  margin: 0px 0px 6px 0px;
  float: left;
}

.sidebar ul.client-list li.client-last {
  margin: 0 0 12px 0;
}

/* About
------------------------------------------------------*/
.teamlist {
  margin: 0;
  padding: 0;
}

.teamlist li {
  width: 296px;
  float: left;
  padding-bottom: 10px;
}

.teamlist li.last {
  float: right;
}

.about-team {
  width: 82px;
  height: 108px;
  float: left;
}

.box-bq {
  padding: 12px 20px 2px 20px;
  background-color: #ebebeb;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px 8px 8px 8px;
  float: left;
}

/* Services
------------------------------------------------------*/
.services-column {
  width: 455px;
  margin: 20px 0px 10px 0px;
  border-bottom: 1px solid #ebebeb;
  float: left;
  height: 180px;
}

.services-column h4 a {
  color: #65696e;
}

.services-spacer {
  width: 50px;
  display: block;
  float: left;
}

.services-spacer-box {
  width: 30px;
  display: block;
  float: left;
}

.more-button {
  height: 29px;
  font-size: 16px;
  color: #3e4043;
  text-align: center;
  margin: 10px 0px 20px 0px;
  padding: 6px 20px 0px 20px;
  background-color: #ebebeb;
  float: right;
}

.more-button a, .more-button a:visited {
  text-decoration: none;
  color: #3e4043;
}

.heading-text {
  font-size: 14px;
  font-style: italic;
  line-height: 24px;
}

.services-margintop {
  margin-top: 40px;
  margin-bottom: 30px;
}

.services-box {
  width: 275px;
  float: left;
}

.services-box p {
  margin-bottom: 23px;
}

/* Portfolio
------------------------------------------------------*/
/* Portfolio Category Filter */
#filter {
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  font: normal 11px/18px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 20px;
}

#filter ul {
  margin: 30px 0;
  padding: 0;
}

#filter li {
  display: inline;
  color: #a1a2a2;
  font-weight: bold;
  margin-right: 5px;
}

#filter li a {
  margin-right: 2px;
  padding: 5px 8px;
  color: #a1a2a2;
  text-decoration: none;
}

#filter li a:hover,
#filter li a.current,
#filter li.current a,
#filter li.current-cat a {
  background-color: #e0e0e0;
  color: #8a8a8a;
  border: 1px solid #dadada;
  text-decoration: none;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.pf-title {
  width: 900px;
  height: 16px;
  float: left;
}

#pf-view {
  width: 60px;
  height: 29px;
  float: left;
}

.pf-box-view {
  background-color: #ebebeb;
  margin-bottom: 25px;
  padding: 12px 12px 13px 12px;
  float: left;
}

.pf {
  margin: 14px 14px 0px 20px;
  float: left;
}

/* List and Grid View */
ul.display {
  float: left;
  width: 960px;
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.display li {
  float: left;
  width: 960px;
  padding: 0px;
  margin: 0;
}

ul.display li .content_block img {
  padding: 0px;
  margin: 0px;
  float: left;
}

ul.display li ul.check-list li {
  float: left;
  width: 463px;
  margin-left: 20px;
}

ul.display li .content_block {
  padding-bottom: 15px;
  margin: 15px 0px 22px 0px;
  border-bottom: 1px solid #ebebeb;
  float: left;
}

ul.display li .content_block h4 {
  width: 485px;
  margin-left: 20px;
  float: left;
  font-weight: normal;
}

ul.display li .content_block h4 a {
  border: none;
  text-decoration: none;
}

ul.display li .content_block p {
  width: 485px;
  font-size: 12px;
  margin-left: 20px;
  float: left;
  margin-bottom: 5px;
}

ul.thumb_view li {
  width: 455px;
  margin: 0px 10px;
}

ul.thumb_view li .pf-box-view {
  background-color: #ebebeb;
  margin-bottom: 25px;
  padding: 12px 12px 3px 12px;
  float: left;
}

ul.thumb_view li .content_block h4 {
  width: 455px;
  margin: -15px 0px 5px 0px;
  float: left;
  font-weight: normal;
}

ul.thumb_view li .content_block p {
  width: 455px;
  font-size: 12px;
  margin-left: 0px;
  float: left;
}

ul.thumb_view li ul.check-list, ul.thumb_view li .more-button {
  display: none;
}

ul.thumb_view li .more-button {
  display: block;
  margin-bottom: 20px;
  margin-left: 0;
}

ul.thumb_view li .content_block a img {
  margin: 0 0 10px;
}

a.switch_thumb {
  width: 60px;
  height: 29px;
  line-height: 26px;
  padding: 0;
  margin: -7px 0px 0px 0px;
  display: block;
  background: url($staticdir + 'images/select-view.gif') no-repeat;
  outline: none;
  text-indent: -9999px;
}

a:hover.switch_thumb {
  opacity: .75;
}

a.swap {
  background-position: left bottom;
}

.single-pf-thumb {
  float: left;
  width: 455px;
}

.single-pf-content {
  float: left;
  width: 455px;
  margin-left: 30px;
}

/* Check list */
ul.check-list,
.maincontent .blog-posted ul,
.pf-box-content ul {
  margin: -3px 0px 20px 0px;
  padding: 0px;
  list-style-type: none;
}

ul.check-list li,
.maincontent .blog-posted ul li,
.pf-box-content ul li {
  background-image: url($staticdir + 'images/check.gif');
  background-position: 0px 7px;
  background-repeat: no-repeat;
  padding-left: 22px;
  line-height: 26px;
}

#portfolio-slider,
.pf-single-image {
  width: 960px;
  height: 364px;
  background-color: #ebebeb;
  float: left;
  margin-bottom: 20px;
}

#portfolio-slider li,
#portfolio-slider ul,
.pf-single-image img {
  float: left;
  margin: 0;
  padding: 12px;
}

#portfolio-slider #pager {
  margin-top: 190px;
  margin-left: 870px;
}

.pf-video-wrapper {
  margin-bottom: 20px;
}

.pf-video-wrapper embed {
  background: #EBEBEB;
}

/* Blog
------------------------------------------------------*/
.blog-post {
  float: left;
}

.left-head {
  width: 40px;
  background-color: #ebebeb;
  margin-right: 14px;
  padding: 7px 0px 7px 0px;
  float: left;
}

.right-head {
  width: 568px;
  border-bottom: 1px solid #ebebeb;
  float: left
}

.blog-posted {
  width: 622px;
  margin: 21px 0px 10px 0px;
  float: left;
}

.blog-posted embed {
  margin-bottom: 10px;
  clear: both;
}

.right-head h3 {
  width: 568px;
  margin-bottom: 2px;
}

.right-head a, .right-head a:visited {
  text-decoration: none;
  color: #65696e;
}

.post-info {
  width: 568px;
  color: #d9d8d8;
  margin-bottom: 5px;
  float: left;
}

.post-info a, .post-info a:visited {
  text-decoration: none;
  color: #d9d8d8;
}

.post-info a:hover {
  text-decoration: underline;
}

.date {
  font-size: 20px;
  color: #65696e;
  line-height: 0px;
  text-align: center;
}

.month {
  font-size: 12px;
  color: #65696e;
  line-height: 0px;
  text-align: center;
}

.blog-box {
  background-color: #ebebeb;
  margin: 0px 15px 10px 0px;
  padding: 12px 10px;
  float: left;
}

/* Author info styling */
.author {
  width: 600px;
  background-color: #ebebeb;
  color: #abacad;
  padding: 10px 15px 6px 15px;
  line-height: 18px;
  float: left;
  margin-bottom: 30px;
}

.author-avatar {
  float: left;
  width: 80px;
}

.author-avatar img {
  position: relative;
}

.author h5 {
  color: #65696e;
  margin: 1px 0px 5px 0px;
}

/* Image and Captions */

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.alignright {
  float: right;
}

.alignleft {
  float: left;
}

/* Blog Pagination */
.blog-pagination {
  float: left;
  margin: 0 0 15px 19px;
}

.pages {
  line-height: 30px;
  margin-bottom: 10px;
  clear: left
}

.blogpages {
  margin: 30px 0px 0px 0px
}

.pfpages {
  margin: -15px 0px 12px 0px;
}

.pageof {
  border: solid 1px #e4e4e4;
  padding: 12px 10px;
  margin-right: 3px;
}

.pages a,
.pages .current {
  border: solid 1px #e4e4e4;
  padding: 12px 10px;
  margin: 0px 3px;
  color: #a7a7a7 !important;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5;
  border-radius: 5px;
}

.pages a:hover {
  color: #fff !important;
  background-color: #e4e4e4;
  text-decoration: none;
}

.pages .current {
  color: #fff !important;
  background-color: #e4e4e4;
  font-weight: bold;
}

/*------- Traditional Navigation -----*/
.page-navigation {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.page-navigation a {
  text-decoration: none;
  color: #353535;
}

.page-navigation a:hover {
  color: #858585;
  text-decoration: none;
}

li.clearpaging {
  clear: both;
}

/* Ads list */
ul.ads-list {
  width: 260px;
  margin: 0px 0px 10px 0px;
  padding: 0px;
  list-style-type: none;
}

ul.ads-list li {
  width: 260px;
  margin: 0px;
  float: left;
}

/* Contact
------------------------------------------------------*/
form {
  margin: 0;
  padding: 0;
}

#contactFormArea {
  width: 406px;
  margin: 49px 0px 0px 86px;
}

#map_wrapper {
  background-color: #ebebeb;
  margin: 0px;
  padding: 12px;
  float: left;
}

#contact_google_map {
  width: 400px;
  height: 250px;
}

.contact-separator {
  width: 52px;
  height: 398px;
  margin-top: 49px;
  background-image: url($staticdir + 'hansili/images/dot-separator.png');
  background-position: right;
  background-repeat: repeat-y;
  float: left;
}

#contact-left {
  width: 395px;
  float: left;
}

#contact-right {
  width: 513px;
  float: left;
}

#maincontactform {
  margin: 20px 0;
}

#maincontactform label {
  font-size: 1.1em;
  width: 100px;
  float: left;
  font-weight: normal;
  clear: both;
  margin-bottom: 3px;
}

.textfield,
input#s {
  float: left;
  font-family: Tahoma, Arial, verdana;
  font-size: 12px;
  margin-bottom: 13px;
  padding: 3px;
  color: #8a8a8a;
  width: 406px;
  height: 22px;
  background-color: #ffffff;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border: 2px solid #ebebeb;
}

.textarea {
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border: 2px solid #ebebeb;
  float: left;
  font-family: Tahoma, Arial, verdana;
  font-size: 12px;
  margin: 0;
  padding: 3px;
  color: #8a8a8a;
  width: 406px;
  height: 116px;
  background-color: #ffffff;
  overflow: hidden
}

.buttoncontact {
  background-image: url($staticdir + 'images/send-now.gif');
  background-repeat: no-repeat;
  padding: 0px;
  margin: 0px;
  border: 0px;
  width: 97px;
  height: 33px;
  cursor: pointer;
  float: right;
  margin-top: 20px;
  margin-right: -10px;
}

@media all and (-webkit-min-device-pixel-ratio: 10000), not all and (-webkit-min-device-pixel-ratio: 0) {
  .buttoncontact {
    background-image: url($staticdir + 'images/send-now.gif');
    background-repeat: no-repeat;
    padding: 0px;
    border: 0px;
    width: 97px;
    height: 33px;
    cursor: pointer;
    float: left;
  }
}

#emailSuccess {
  width: 355px;
  border-top: 1px solid #91E691;
  border-bottom: 1px solid #91E691;
  background-color: #E0FFE0;
  color: #007500;
  text-align: center;
  padding: 7px 30px;
}

.require {
  display: none;
}

.loading {
  margin-top: 15px;
  float: left;
  background: url($staticdir + 'images/loading-contact.gif') top left no-repeat;
  padding-left: 32px;
  font-size: 1.1em;
  color: #a5a6a6;
  margin-left: 10px;
}

label.screen-reader-text {
  display: none;
}

input#searchsubmit {
  padding: 7px 5px;
  margin-left: 10px;
}

.sidebar input#s {
  margin-right: 10px;
  width: 240px;
}

.sidebar input#searchsubmit {
  float: left;
  margin-left: 0;
  margin-bottom: 10px;
}

.sidebar label.screen-reader-text {
  display: none;
}

/* Footer
------------------------------------------------------*/
#footer-content {
  width: 960px;
  height: 263px;
  background-image: url($staticdir + 'images/footer-glow.png');
  background-repeat: no-repeat;
  background-position: top center;
  margin: 0px auto;
}

#footer-address {
  width: 273px;
  margin-top: 28px;
  float: left;
}

#footer-address img {
  margin-bottom: 10px;
}

#footer-last {
  width: 960px;
  margin-top: 26px;
  border-top: 2px solid #979696;
  float: left;
}

/* Footer News list */
#footer-news {
  width: 460px;
  margin-top: 28px;
  float: right;
}

#footer-news h3 {
  margin-bottom: 7px;
  color: #e3e3e3;
}

ul.list-bottom,
.footer-widget ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

ul.list-bottom li,
.footer-widget ul li {
  background-image: url($staticdir + 'images/bottom-dot.png');
  background-position: bottom;
  background-repeat: repeat-x;
  padding-bottom: 4px;
  line-height: 26px;
}

ul.list-bottom li a,
.footer-widget ul li a {
  color: #a6a6a6;
  text-decoration: none;
}

ul.list-bottom li a:hover,
.footer-widget ul li a:hover {
  text-decoration: underline;
}

#footer-copyright {
  font-size: 11px;
  margin-top: 10px;
  float: right;
}

/* footermenu Styling */
#footer-menu {
  width: 500px;
  margin: 0px;
  padding: 0px;
  float: left;
}

.navigation-footer {
  width: 543px;
  float: left;
  margin: 9px 0px 0px 0px;
  padding: 0px;
  list-style-type: none;
}

.navigation-footer ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  position: relative;
  clear: both;
}

.navigation-footer li {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  float: left;
  z-index: 100;
  position: relative;
}

.navigation-footer li a {
  font-size: 11px;
  text-decoration: none;
  color: #a6a6a6;
  display: block;
  margin: 0px;
  padding-right: 24px;
  float: left;
  z-index: 100;
}

.navigation-footer li.current a, .navigation-footer li a:hover, .navigation-footer li.current li a:hover {
  text-decoration: none;
  color: #a6a6a6;
}

.navigation-footer li.current li a {
  text-decoration: none;
  color: #a6a6a6;
}

/* Comment list */
#comment {
  width: 100%;
  float: left;
  padding: 20px 0 8px;
}

.comment-text h6,
#reply-title {
  margin-bottom: -2px;
  font-size: 18px;
}

#comment ol {
  list-style-type: none !important;
  margin: 0 !important;
  padding: 10px 0 0 0;
}

.commentlist {
  margin: 0;
}

.commentlist li {
  position: relative;
  list-style: none;
  line-height: 22px;
  list-style-position: outside;
  background: none;
  margin: 0;
}

.commentlist ul.children {
  margin: 0px 0px 0 64px;
  padding-top: 10px;
}

.commentlist ul.children li {
  background: none;
}

.commentlist li small {
  font-size: 11px;
  text-transform: uppercase;
}

.commentlist li p {
  margin-top: 10px;
}

.avatar img {
  border: 1px solid #dcdcdc;
  padding: 4px;
}

.comment-text {
  border-bottom: 1px solid #dcdcdc;
  margin-left: 85px;
  width: auto;
  margin-bottom: 10px;
  padding: 0px 0px 7px;
}

.comment-text p {
  font-size: 13px;
  margin-bottom: 5px;
  line-height: 24px;
}

a.comment-reply-link, a:visited.comment-reply-link {
  padding: 0px 6px !important;
  font-size: 10px;
  color: #fff;
  text-transform: lowercase;
  text-decoration: none;
  background-color: #868686;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5;
  border-radius: 5px;
  position: relative;
  top: -15px;
  float: right;
}

h3#reply-title {
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 10px;
  margin-bottom: 10px;
  width: 100%;
}

/* Comment form */
#commentform-wrap {
  width: 100%;
  margin-top: 20px;
  float: left;
}

fieldset {
  border: 0;
}

form#comment-form label {
  font-size: 13px;
  line-height: 57px;
  width: 37%;
  margin-top: -11px;
  margin-bottom: 0px;
  float: right;
}

form#comment-form input {
  width: 60%;
  margin-bottom: 15px;
  float: left;
}

form#comment-form textarea {
  margin-bottom: 15px;
  overflow: hidden;
  float: left;
  padding: 10px;
  width: 96%;
}

form#comment-form #submit, #searchsubmit {
  width: 90px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  padding: 10px 12px;
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  background-color: #575757;
  background-image: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  float: left;
}

#searchsubmit {
  background-color: #a8a8a8;
}

form#comment-form em {
  color: #db4e43;
  display: inline;
}

/* WP Calendar */
table#wp-calendar {
  border-collapse: collapse;
}

table#wp-calendar caption {
  font-size: 1.4em;
}

tbody td.pad {
  width: 30px;
  background-color: #fff;
}

table#wp-calendar > tbody > tr > td > a {
  font-weight: bold;
  font-size: 14px;
  font-family: arial narrow, arial, garamond, palatino linotype, times new roman, bookman old style;
}

thead > tr > th {
  padding: 10px;
  text-align: center;
  background-color: #fff;
}

tbody > tr > td {
  padding: 10px 12px;
  text-align: center;
}

tbody > tr > td#today {
  color: green;
  font-weight: bold;
}

tfoot > tr > td > a, tfoot > tr > td > a:link, tfoot > tr > td > a:visited, tfoot > tr > td > a:hover, tfoot > tr > td > a:active {
  font-weight: bold;
  font-size: 14px;
  height: 20px;
  font-family: garamond, bookman old style, times new roman, arial narrow, arial;
}

tfoot > tr > td#prev {
  width: 40px;
  margin: 3px;
  padding-left: 10px;
  text-align: left;
  background-color: #ffffff;
}

tfoot > tr > td#next {
  width: 40px;
  margin: 3px;
  text-align: right;
  background-color: #ffffff;
}

/* Twitter */
div#twitter {
}

p.tweet_list {
  margin: 0;
  padding: 1em 1em 1em 3em;
  background: transparent url($staticdir + 'images/loading.gif') 1em center no-repeat;
}

.tweet_list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.tweet_list li {
  margin: 8px 0;
  padding-bottom: 8px;
  font-size: 12px;
  color: #868686;
  font-style: italic;
  line-height: 21px !important;
}

.tweet_list li a {
  background: none;
  padding: 0;
  /* timestamp link */
}

.tweet_list li span {
  display: block;
}

.tweet_list li span a {
}

.tweet_list li span a:hover {
}

.tweet_list li.firstTweet {
}

.tweet_list li span.tweet_time {
  display: none;
}

/* Wordpress Default Styling */
.wp-caption {
}

.wp-caption-text {
}

.sticky {
}

.gallery-caption {
}

.bypostauthor {
}

.col_12 {
  width: 470px;
  display: inline;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

.col_12_last {
  width: 470px;
  display: inline;
  float: right;
  margin-right: 0;
  margin-bottom: 20px;
}

.col_13 {
  width: 306px;
  display: inline;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

.col_23 {
  width: 633px;
  display: inline;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

.col_23_last {
  width: 633px;
  display: inline;
  float: left;
  margin-right: 0;
  margin-bottom: 20px;
}

.col_13_last {
  width: 306px;
  display: inline;
  float: right;
  margin-right: 0;
  margin-bottom: 20px;
}

.col_14 {
  width: 225px;
  display: inline;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

.col_14_last {
  width: 225px;
  display: inline;
  float: right;
  margin-right: 0;
  margin-bottom: 20px;
}

.col_34 {
  width: 715px;
  display: inline;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

.col_34_last {
  width: 715px;
  display: inline;
  float: left;
  margin-right: 0;
  margin-bottom: 20px;
}

/* Columns Inner */
.col_12_inner {
  width: 305px;
  display: inline;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

.col_12_inner_last {
  width: 305px;
  display: inline;
  float: right;
  margin-right: 0;
  margin-bottom: 20px;
}

.col_13_inner {
  width: 196px;
  display: inline;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

.col_13_inner_last {
  width: 196px;
  display: inline;
  float: right;
  margin-right: 0;
  margin-bottom: 20px;
}

.col_23_inner {
  width: 414px;
  display: inline;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

.col_14_inner {
  width: 142px;
  display: inline;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

.col_14_inner_last {
  width: 142px;
  display: inline;
  float: right;
  margin-right: 0;
  margin-bottom: 20px;
}

.col_34_inner {
  width: 468px;
  display: inline;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

/***** Pricing Plan Styling *****/
.pricing-slogan {
  margin-bottom: 2px;
}

.pricing-wrapper {
  padding: 35px 0 30px;
  clear: both;
}

.pricing-wrapper .pricing-column:first-child {
  margin-left: 1px;
}

.pricing-column {
  width: 24.8%;
  margin-left: -1px;
  background-color: #f5f5f5;
  border: 1px solid #e4e4e4;
  float: left;
}

.third-col .pricing-column {
  width: 33%;
}

.fourth-col .pricing-column {
  width: 24.8%;
}

.fifth-col .pricing-column {
  width: 19.8%;
}

.pricing-title {
  border-bottom: 1px solid #f00;
  text-align: center;
  padding: 20px 12px;
}

.pricing-price {
  margin-top: -15px;
  background-color: #fff;
  border-bottom: 1px solid #e4e4e4;
  text-align: center;
  padding: 6px 0 0;
}

.pricing-price h1 {
  font-size: 42px;
  font-weight: 700;
  text-shadow: 3px 3px 0 #dadada;
}

.pricing-price p {
  margin-top: -12px;
  font-style: italic;
  font-family: Georgia, "Times New Roman", Times, serif;
  margin-bottom: 24px;
}

.pricing-feature {
  width: 85%;
  text-align: center;
  margin: 0 auto;
}

.pricing-feature ul li {
  font-size: 11px;
  font-family: Tahoma, Geneva, sans-serif;
  border-bottom: 1px solid #e4e4e4;
  margin: 0;
  padding: 10px 8px;
}

.pricing-button {
  text-align: center;
  margin-top: -1px;
  background-color: #e4e4e4;
  border-top: 1px solid #e4e4e4;
  padding: 20px 0 10px;
}

.feature-package {
  border: 6px solid #666;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .15);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, .15);
  box-shadow: 0 0 20px rgba(0, 0, 0, .15);
  position: relative;
  z-index: 100;
  margin: -15px -5px 0;
}

.feature-package .pricing-button {
  padding: 25px 0 20px;
}

.feature-package .pricing-button a {
  margin-top: 5px;
}

/***** Tables  *****/

table {
  width: 100%;
  margin: 0 0 20px;
  border-collapse: collapse;
  border-spacing: 0;
}

table th {
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  font-family: 'Open Sans', Georgia, Arial;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -1px;
  text-align: left;
  padding: 12px;
}

table td {
  background-color: #f0f0f0;
  color: #828282;
  text-align: left;
  padding: 12px;
}

table td.highlight {
  color: #FFF;
  font-weight: 700;
}

.table-green table {
  border: 1px solid #749707;
}

.table-green table tr th {
  background-color: #91bd09;
}

.table-cyan table {
  border: 1px solid #389f9f;
}

.table-cyan table tr th {
  background-color: #46C7C7;
}

.table-purple table {
  border: 1px solid #606;
}

.table-purple table tr th {
  background-color: purple;
}

.table-brown table {
  border: 1px solid #630;
}

.table-brown table tr th {
  background-color: #804000;
}

.table-rosy table {
  border: 1px solid #c15663;
}

.table-rosy table tr th {
  background-color: #F16C7C;
}

.table-gold table {
  border: 1px solid #aa8012;
}

.table-gold table tr th {
  background-color: #D4A017;
}

.table-pink table {
  border: 1px solid #b51a75;
}

.table-pink table tr th {
  background-color: #e22092;
}

.table-blue table {
  border: 1px solid #008abe;
}

.table-blue table tr th {
  background-color: #00ADEE;
}

.table-yellow table {
  border: 1px solid #cc9111;
}

.table-yellow table tr th {
  background-color: #ffb515;
}

.table-magenta table {
  border: 1px solid #87013c;
}

.table-magenta table tr th {
  background-color: #A9014B;
}

.table-orange table {
  border: 1px solid #cc4a00;
}

.table-orange table tr th {
  background-color: #ff5c00;
}

.table-red table {
  border: 1px solid #b81f1f;
}

.table-red table tr th {
  background-color: #e62727;
}

.table-gray table {
  border: 1px solid #525252;
}

.table-gray table tr th {
  background-color: #666;
}

.table-black table {
  border: 1px solid #292929;
}

.table-white .table th {
  color: #666;
}

.table-white table {
  border: 1px solid #ccc;
}

.table-white table tr th {
  background-color: #FFF;
}

table tr.even td, table tr.odd td {
  background-color: #fff;
}

table tr td.highlight, .table-black table tr th {
  background-color: #333;
}

/***** FAQ STYLING *****/
.toggle_wrapper {
  margin-bottom: 30px;
}

.toggle {
  margin-bottom: 5px;
}

.toggle_title,
.accordion .tab {
  cursor: pointer;
  font-size: 14px;
  background: url($staticdir + 'images/faq-closed.png') 98% 50% no-repeat;
  background-color: #f0f0f0;
  border: 1px solid #d4d4d4;
  padding: 8px 13px 10px 13px;
  clear: both;
}

.toggle_active,
.accordion .tab.current {
  background: url($staticdir + 'images/faq-open.png') 98% 50% no-repeat;
  background-color: #f0f0f0;
}

.toggle_content,
.accordion .pane {
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
  margin: 0px 0px 5px 0px;
  padding: 15px 18px 1px 18px;
  display: none;
}

/***** Tab Styling *****/
ul.tabs {
  display: block;
  margin-bottom: 0;
  height: 40px;
  list-style: none;
  padding: 4px 0 0 2px;
  float: left;
}

ul.tabs li {
  float: left;
  height: 40px;
}

ul.tabs li a {
  display: block;
  height: 38px;
  line-height: 40px;
  border-top: solid 1px #dfdfdf;
  border-left: solid 1px #dfdfdf;
  border-right: solid 1px #dfdfdf;
  color: #7d7b7b;
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  background: #f5f5f5;
  font-size: 13px;
  text-decoration: none;
  margin: 0 0 0 -1px;
  padding: 0 20px;
  position: relative;
  z-index: 100;
}

ul.tabs li.current a {
  position: relative;
  height: 39px;
  top: -2px;
  padding-top: 4px;
  background: #fff;
  border-bottom: none;
  color: #7d7b7b;
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  z-index: 100;
}

.panes {
  width: 99.6% !important;
  margin: 0px 0px 12px 1px;
  border-top: none;
  clear: both;
  background: #fff;
  position: relative;
  float: left;
}

.pane {
  padding: 12px 18px 10px;
  line-height: 21px;
}

.pane p {
  padding-top: 5px;
}

.pane img {
  float: left;
}

/* Accordion
---------------------------------------------------------- */
.accordion {
  margin-bottom: 20px;
}

.red_alert {
  color: #a94442 !important;
  input {
    background-color: #f2dede !important;
    border-color: #ebccd1 !important;
  }
  textarea {
    background-color: #f2dede !important;
    border-color: #ebccd1 !important;
  }
}

.middle {
  margin: 0 auto;
}

.bottom-margin {
  margin-bottom: 15px;
}

.bottom-margin30 {
  margin-bottom: 30px;
}

.post-top {
  padding-top: 15px;
}

.media-body {
  padding-top: 4px;
  height: 121px;
}