@import "variables";

.bottom-container {
  background-color: #416300;
}

.footer-last {
  border-top: 2px solid #979696;
}

.footer-glow {
  background: url("/assets/images/footer-glow.png") no-repeat top center;
}